import { BaseModal } from './BaseModal'
import './ModalStyles.css'
import { hint } from '../../lib/words'

type Props = {
  isOpen: boolean
  handleClose: () => void
}

export const HintModal = ({ isOpen, handleClose }: Props) => {
  return (
    <BaseModal title="Hint" isOpen={isOpen} handleClose={handleClose}>
      <div className="flex flex-col mt-2 text-gray-900 dark:text-gray-100">
        {hint}
      </div>
    </BaseModal>
  )
}
