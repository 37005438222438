import {
  ChartBarIcon,
  CogIcon,
  InformationCircleIcon,
  SearchIcon,
  LibraryIcon,
  ShoppingBagIcon,
} from '@heroicons/react/outline'
import { GAME_TITLE } from '../../constants/strings'

type Props = {
  setIsInfoModalOpen: (value: boolean) => void
  setIsHintModalOpen: (value: boolean) => void
  setIsSettingsModalOpen: (value: boolean) => void
  setIsStatsModalOpen: (value: boolean) => void
  setIsLessonModalOpen: (value: boolean) => void
  gameFinished: boolean
}

export const Navbar = ({
  setIsInfoModalOpen,
  setIsHintModalOpen,
  setIsSettingsModalOpen,
  setIsStatsModalOpen,
  setIsLessonModalOpen,
  gameFinished,
}: Props) => {
  return (
    <div className="navbar">
      <div className="navbar-content px-5">
        <div className="flex">
          <InformationCircleIcon
            className="h-6 w-6 mr-2 cursor-pointer dark:stroke-white"
            onClick={() => setIsInfoModalOpen(true)}
          />
          <ShoppingBagIcon
            className="h-6 w-6 mr-2 cursor-pointer dark:stroke-white"
            onClick={() => window.open('https://mediclefun.myshopify.com/')}
          />
        </div>
        <p className="md:text-5xl sm:text-4xl font-mono font-bold dark:text-white title ">
          {GAME_TITLE}
        </p>
        <div className="right-icons">
          {gameFinished && (
            <LibraryIcon
              className="h-6 w-6 mr-3 cursor-pointer dark:stroke-white"
              onClick={() => setIsLessonModalOpen(true)}
            />
          )}
          <ChartBarIcon
            className="h-6 w-6 mr-3 cursor-pointer dark:stroke-white"
            onClick={() => setIsStatsModalOpen(true)}
          />
          <SearchIcon
            className="h-6 w-6 mr-3 cursor-pointer dark:stroke-white"
            onClick={() => setIsHintModalOpen(true)}
          />
          <CogIcon
            className="h-6 w-6 cursor-pointer dark:stroke-white"
            onClick={() => setIsSettingsModalOpen(true)}
          />
        </div>
      </div>
      <hr></hr>
    </div>
  )
}
