export const WORDS = [
  {
    word: 'cauda',
    hint: 'horse',

    lesson:
      'The cauda equina (from Latin horses tail) is a bundle of spinal nerves and spinal nerve rootlets, consisting of the second through fifth lumbar nerve pairs, the first through fifth sacral nerve pairs, and the coccygeal nerve, all of which arise from the lumbar enlargement and the conus medullaris of the spinal cord. The cauda equina occupies the lumbar cistern, a subarachnoid space inferior to the conus medullaris. The nerves that compose the cauda equina innervate the pelvic organs and lower limbs to include motor innervation of the hips, knees, ankles, feet, internal anal sphincter and external anal sphincter. In addition, the cauda equina extends to sensory innervation of the perineum and, partially, parasympathetic innervation of the bladder.',

    link: 'https://en.wikipedia.org/wiki/Cauda_equina#:~:text=The%20cauda%20equina%20(from%20Latin,medullaris%20of%20the%20spinal%20cord.',
  },

  {
    word: 'orbit',
    hint: 'eye',

    lesson:
      'The orbital contents comprise the eye, the orbital and retrobulbar fascia, extraocular muscles, cranial nerves II, III, IV, V, and VI, blood vessels, fat, the lacrimal gland with its sac and duct, the eyelids, medial and lateral palpebral ligaments, cheek ligaments, the suspensory ligament, septum, ciliary ganglion and short ciliary nerves.',

    link: 'https://en.wikipedia.org/wiki/Orbit_(anatomy)',
  },

  {
    word: 'Nissl',
    hint: 'RER',

    lesson:
      'Nissl bodies (also called Nissl granules, Nissl substance or tigroid substance) are discrete granular structures in neurons that consist of rough endoplasmic reticulum, a collection of parallel, membrane-bound cisternae studded with ribosomes on the cystosolic surface of the membranes. NISSL STAIN REVEALS AXON and DENDRITES, NOT MICROGLIA.',

    link: 'https://en.wikipedia.org/wiki/Nissl_body',
  },

  {
    word: 'ovale',
    hint: 'heart',

    lesson:
      'In the fetal heart, the foramen ovale allows blood to enter the left atrium from the right atrium. It is one of two fetal cardiac shunts, the other being the ductus arteriosus (which allows blood that still escapes to the right ventricle to bypass the pulmonary circulation). Another similar adaptation in the fetus is the ductus venosus. In most individuals, the foramen ovale closes at birth. It later forms the fossa ovalis.',

    link: 'https://en.wikipedia.org/wiki/Foramen_ovale_(heart)',
  },

  {
    word: 'stemi',
    hint: 'chest pain',

    lesson:
      ' STEMI stands for ST-elevation myocardial infarction. It is a type of heart attack that is caused by a complete blockage of a coronary artery, resulting in a lack of blood and oxygen to a portion of the heart muscle. The blockage is usually due to a build-up of plaque in the artery, which can rupture and cause a blood clot to form. The most common symptom of a STEMI is chest pain, which may radiate to the arms, neck, or jaw. Other symptoms may include shortness of breath, nausea, vomiting, and lightheadedness. Diagnosis of a STEMI is typically made based on the presence of certain electrocardiogram (ECG) findings, such as ST-segment elevation, and may be confirmed with blood tests to measure cardiac enzymes. Treatment usually involves immediate medical attention and may include medications to break up the blood clot and restore blood flow to the heart, as well as procedures such as angioplasty or coronary artery bypass surgery.',

    link: 'https://en.wikipedia.org/wiki/Myocardial_infarction#STEMI',
  },

  {
    word: 'Utero',
    hint: 'Female Reproduction',

    lesson:
      ' The term "utero" refers to the uterus, which is a reproductive organ located in the female pelvis. The uterus is a muscular, pear-shaped organ that is responsible for supporting and nourishing a developing fetus during pregnancy. It is made up of three layers: the inner lining (endometrium), the middle muscle layer (myometrium), and the outer layer (perimetrium). During pregnancy, the endometrium thickens and becomes rich in blood vessels to support the growth of the fetus. The myometrium contracts during labor to help push the baby out of the uterus and into the birth canal. The perimetrium is a thin layer of tissue that covers the outer surface of the uterus.',

    link: 'https://en.wikipedia.org/wiki/Uterus',
  },

  {
    word: 'Torch',
    hint: 'Vertical transmission',

    lesson:
      'The TORCHHES mnemonic is for vertically transmitted infections. Toxoplasmosis, Other, Rubella, CMV, HIV, Herpes, Syphilis. Other includes Parvovirus, Zika',

    link: 'https://en.wikipedia.org/wiki/Vertically_transmitted_infection',
  },

  {
    word: 'Myoma',
    hint: 'Muscle',

    lesson:
      ' A myoma, also known as a fibroid or uterine myoma, is a benign tumor that grows in the muscle tissue of the uterus. Myomas are common and are typically found in women of reproductive age, but they can also occur in women who have gone through menopause. Myomas are usually asymptomatic, meaning they do not cause any symptoms, and are often discovered during routine pelvic exams or imaging tests. However, in some cases, myomas can cause symptoms such as heavy or irregular periods, pain during intercourse, frequent urination, and abdominal pressure or pain.',

    link: 'https://en.wikipedia.org/wiki/Myoma',
  },

  {
    word: 'Aorta',
    hint: 'Large Vessel',

    lesson:
      'Aortic aneurysm is a dilation of all 3 layers of the vessel wall due to transmural inflammation. There are 2 primary locations for Aortic Aneursym which are thoracic and abodmoinal aortic aneursyms. Thoracic AA is typically a result of hypertension but can also be due to risk factors such as tertiary syphilis, connective tissue disease, or bicuspid aortic valve. Abdominal AA (AAA) is most often due to smoking. TAA presents with chest pain, hoarseness (compression of recurrent laryngeal), and dysphagia (esophageal compression). AAA presents with low back pain and pulsatile abdominal mass. AAA is almost always infrarenal meaning the renal arteries are above the dilation and as a result the blood flow to the kidneys is maintained.',

    link: 'https://en.wikipedia.org/wiki/Aorta',
  },

  {
    word: 'Ovary',
    hint: 'Female Reproduction',
    lesson:
      ' The ovaries are made up of three main layers: the outer layer (tunica albuginea), the middle layer (stroma), and the inner layer (germinal epithelium). The outer layer is a thin layer of connective tissue that surrounds the ovary. The middle layer contains the ovarian follicles, which are sacs that contain the eggs. The inner layer is the inner lining of the ovary and is responsible for producing hormones such as estrogen and progesterone. During the menstrual cycle, an ovary releases an egg (ovulation) and the egg travels through the fallopian tube to the uterus. If the egg is fertilized by a sperm, it implants in the uterine lining and pregnancy occurs. If the egg is not fertilized, it is shed along with the uterine lining during menstruation.',

    link: 'https://en.wikipedia.org/wiki/Ovary',
  },

  {
    word: 'valve',
    hint: 'Heart',

    lesson:
      'The valves of the heart include the Tricuspid, Mitral, Pulmonic, and Aortic valves. The locations of these valves is often remembered with the APTM mnemonic corresponding to the Right upper sternal border (a), left upper sternal border (p) left lower sternal border (t) and left 5th intercostal space in midclavicular line at apex of heart (m).',

    link: 'https://en.wikipedia.org/wiki/Heart_valve',
  },

  {
    word: 'ewing',
    hint: 'sarcoma',

    lesson:
      ' Ewing sarcoma starts in the cells that form the bones and can spread to other parts of the body, such as the lungs or the lymph nodes. It often develops in the long bones of the arms and legs, but it can also occur in the chest, pelvis, and other bones.',

    link: 'https://en.wikipedia.org/wiki/Ewing_sarcoma',
  },

  {
    word: 'sonic',
    hint: 'Video Game',

    lesson:
      ' Sonic hedgehog protein (SHH) is encoded for by the SHH gene. The protein is named after the character Sonic the Hedgehog. This signaling molecule is key in regulating embryonic morphogenesis in all animals. SHH controls organogenesis and the organization of the central nervous system, limbs, digits and many other parts of the body.',

    link: 'https://en.wikipedia.org/wiki/Sonic_hedgehog_protein',
  },

  {
    word: 'thumb',
    hint: 'Hand',

    lesson:
      ' The skeleton of the thumb consists of the first metacarpal bone which articulates proximally with the carpus at the carpometacarpal joint and distally with the proximal phalanx at the metacarpophalangeal joint. This latter bone articulates with the distal phalanx at the interphalangeal joint. Additionally, there are two sesamoid bones at the metacarpophalangeal joint.',

    link: 'https://en.wikipedia.org/wiki/Thumb',
  },

  {
    word: 'fried',
    hint: 'Egg',

    lesson:
      'Oligodendrogliomas are adult primary brain tumors that are rare and slow growing. They occur in the frontal lobes and can become calcified. They originate from oligodendrocytes and have a fried egg appearance on histopathology due to the round nucleus and clear cytoplasm.',

    link: 'https://en.wikipedia.org/wiki/Oligodendroglioma',
  },

  {
    word: 'croup',
    hint: 'Steeple',

    lesson:
      'Acute Laryngotracheobronchitis aka croup is caused by parainfluenza virus and is characterized by a seal like barking cough. It also presents with inspiratory stridor due to subglottic narrowing which is depicted as a steeple sign on xray. It can also lead to pulsus paradoxus in severe cases.',

    link: 'https://en.wikipedia.org/wiki/Croup',
  },

  {
    word: 'pompe',
    hint: 'GSD',

    lesson:
      "Glycogen Storage Disease II aka Pompe disease is a deficiency of Lysosomal acid a-1,4-glucosidase. The mnemonic for this is P*omP*e trashes the P*umP*. Because the 1st and 4th letter are P's you can remember the deficient enzyme begins with 1,4. ",

    link: 'https://en.wikipedia.org/wiki/Glycogen_storage_disease_type_II',
  },

  {
    word: 'bovis',
    hint: 'Micro',

    lesson:
      'Strep Bovis is a gram + cocci that colonizes the gut. It encompasses Strepg gallolyticus which is S. Bovis biotype 1. Remember that Bovis in Blood= Cancer in Colon because a colonoscopy is indicated with positive S. Bovis.',

    link: 'https://en.wikipedia.org/wiki/Streptococcus_bovis',
  },

  {
    word: 'snare',
    hint: 'Botulism',

    lesson:
      'Tetanus toxin and Botulinum toxin both cleave SNARE proteins involved in neurotransmission.',

    link: 'https://en.wikipedia.org/wiki/SNARE_(protein)',
  },

  {
    word: 'fungi',
    hint: 'Mushroom',

    lesson:
      'Dimorphic fungi are those that have different forms in different temperatures. The most common rule is that these fungi are mold in the cold and yeast in the heat. For systemic mycoses, this rule applies to histoplasmosis, blastomycosis, and Paracoccidioidomycosis. However, the outlier in this group is Coccidioidomycosis which is a spherule in heat (tissue).',

    link: 'https://en.wikipedia.org/wiki/Fungal_infection',
  },

  {
    word: 'marie',
    hint: 'Charcot',

    lesson:
      ' Group of progressive hereditary nerve disorders related to the defective production of proteins involved in the structure and function of peripheral nerves or the myelin sheath. Typically autosomal dominant and associated with foot deformities (eg, pes cavus, hammer toe), lower extremity weakness (eg, foot drop), and sensory deficits. Most common type, CMT1A, is caused by PMP22 gene duplication',
    link: 'https://en.wikipedia.org/wiki/Charcot%E2%80%93Marie%E2%80%93Tooth_disease',
  },
]
//   {
//     word: 'theta',
//     hint: 'Waves',
//     lesson:
//       'Theta waves are characteristic of the first stage of Non-REM sleep (N1 phase). N1 lasts for 5% of sleep (shortest) and is essentially just a transition into sleeping. People who awaken in N1 often deny sleeping. N2 appears on EEG as sleep spindles and K complexes and is when tooth grinding AKA bruxism occurs ("two-oth"). N3 is the deepest non-REM sleep characterized by slow Delta waves which are low frequency and high amplitude. N3 is when sleepwalking, night terrors, and bedwetting occurs ("wee and flee in N3"). REM sleep is characterized by fast beta waves which are high frequency and low amplitude. REM sleep is when extraocular movements, dreaming, nightmares, and penile tumescence occurs. Time spent is as follows N1 (5%), N2 (45%), N3 (25%), REM (25%): note that N2 is the longest and N3=REM while N1 is the shortest. MNEMONIC= (at night...) BATS Drink Blood:  awake w/ eyes open (Beta), awake w/ eyes closed (Alpha), N1 (Theta), N2 (Spindles and K complexes), N3 (Delta), REM (Beta). ',
//     link: 'https://en.wikipedia.org/wiki/Theta_wave',
//   },

//   {
//     word: 'tinea',
//     hint: 'fungi',
//     lesson:
//       'Tinea is a clinical name for dermatophytes, or cutaneous fungal infectinos. Dermatophytes include Microsporum, Trichophyton, and Epidermophyton. They are associated with pruritis and appear on KOH blue staining as branching septae hyphae. Tinea capitis (head), Tinea corporis (torso, AKA ringworm), Tinea cruris (inguinal), Tinea pedis (foot), and Tinea unguium (Onychomycosis under nails) are varieties.',
//     link: 'https://en.wikipedia.org/wiki/Dermatophytosis',
//   },

//   {
//     word: 'anion',
//     hint: 'acidosis',
//     lesson:
//       'In cases of metabolic acidosis, the anion gap is calculated to determine the ionic source of acidosis. The formula is AG= Na- HCO3 - Cl. If the anion gap is >12 mEq/L then the sources of AG metabolic acidosis can be remembered with the mnemonic MUDPILES (Methanol, Uremia, Diabetic Ketoacidosis, Propylene Glycol, Iron Tablets or INH, Lactic Acidosis, Ethylene glycol (oxalic acid), and Salicylates*. Note: salicylate toxicitiy initially appears as respiratory alkalosis but later progresses to anion gap metabolic acidosis.',
//     link: 'https://en.wikipedia.org/wiki/High_anion_gap_metabolic_acidosis',
//   },

//   {
//     word: 'lymph',
//     hint: 'thoracic duct',
//     lesson:
//       'The lymph of the body enters systemic circulation through 2 connections. Approximately 75% of the body (Left upper body and entire lower body) has its lymph drained through the thoracic duct which connects at the junction of the left subclavian and left internal jugular veins. The lymphatic duct drains the right upper body and connects at the junction of the right subclavian and right internal jugular veins.',
//     link: 'https://en.wikipedia.org/wiki/Thoracic_duct',
//   },

//   {
//     word: 'tibia',
//     hint: 'leg',
//     lesson:
//       'The tibia AKA shin bone is one of two long bones in the leg along with the fibula. The tibia is located medially while the fibula is more lateral. It articulates with the femur superiorly and is attached to the fibula by the interosseus membrane. The tibia is the second largest bone in the human body after the femur.',
//     link: 'https://en.wikipedia.org/wiki/Tibia',
//   },

//   {
//     word: 'Henle',
//     hint: 'nephron',
//     lesson:
//       'The loop of henle is a portion of the nephron comprised mainly of the thin descending limb and thick ascending limb. The loop of henle functions to create a medullary concentration gradient. The thin descending limb is impermeable to sodium so it concentrates the urine while the thick ascending loop is impermeable to water thus it dilutes the urine.',
//     link: 'https://en.wikipedia.org/wiki/Loop_of_Henle',
//   },
//   {
//     word: 'Krebs',
//     hint: 'cycle',
//     lesson:
//       'The Krebs cycle AKA citric acid or TCA cycle is responsible for deriving energy by oxidating acetyl-CoA. A mnemonic for the substrates of the TCA cycle is: Please, Can I Keep Selling Seashells For Money Officer? (Pyruvate, Citrate, Isocitrate, a-Ketoglutarate, Succinyl-coA, Succinate, Fumarate, Malate, Oxaloacetate).',
//     link: 'https://en.wikipedia.org/wiki/Citric_acid_cycle',
//   },
//   {
//     word: 'Chyli',
//     hint: 'Lymph',
//     lesson:
//       'The cisterna chyli is a retroperitoneal structure that receives chyle (lipids) and lymphatic drainage from the intestines and lumbar regions. It is a dilated sac at the most inferior portion of the thoracic duct, it lies posterior to the abdominal aorta.',
//     link: 'https://en.wikipedia.org/wiki/Cisterna_chyli',
//   },

//   {
//     word: 'vomer',
//     hint: 'nasal septum',
//     lesson:
//       ' The vomer is an unpaired bone in the midsagittal plane of the skull that makes up the inferior portion of the nasal septum.',
//     link: 'https://en.wikipedia.org/wiki/Vomer',
//   },

//   {
//     word: 'incus',
//     hint: 'anvil',
//     lesson:
//       'The incus AKA anvil is one of three ossicles involved in conductance in the middle ear. The incus articulates with both the malleus and the stapes. The order of conductance is tympanic membrane>malleus>incus>stapes>oval window.',
//     link: 'https://en.wikipedia.org/wiki/Incus',
//   },

//   {
//     word: 'Monro',
//     hint: 'CSF',
//     lesson:
//       'The Foramina of Monro are connections that allow drainage between the lateral ventricles and the third ventricle. The flow of CSF is as follows: Lateral Ventricles>Foramina of Monro> 3rd Ventricle> Cerebral acqueduct> 4th Ventricle> Lateral Foramina of Lushka OR Medial Foramen of Magendie> Subarachnoid Space> Arachnoid Granulations> Sinuses> Internal Jugular vein',
//     link: 'https://en.wikipedia.org/wiki/Interventricular_foramina_(neuroanatomy)',
//   },
//   {
//     word: 'Weber',
//     hint: 'Auditory',
//     lesson:
//       'Weber test is done by placing tuning fork on top of the skull. If there is sensorineural hearing loss, patient will hear sound louder at unaffected ear as the injured ear will have no ability to conduct signal to brain. If there is conductive hearing loss, the sound should be louder in the affected ear. To remember this, try covering one ear with your hand and speaking, you will hear your voice louder in the covered ear because there is less background noise. Thus, in conductive hearing loss, there will be more transmission to the affected ear. ',
//     link: 'https://en.wikipedia.org/wiki/Weber_test',
//   },
// ]
