import { BaseModal } from './BaseModal'
import { ChartBarIcon } from '@heroicons/react/outline'
import './ModalStyles.css'
import { solution, lesson, link } from '../../lib/words'
import { SHARE_TEXT } from '../../constants/strings'
import { shareStatus } from '../../lib/share'

type Props = {
  isOpen: boolean
  handleClose: () => void
  setIsStatsModalOpen: (value: boolean) => void
  guesses: string[]
  isGameLost: boolean
  isHardMode: boolean
  isDarkMode: boolean
  isHighContrastMode: boolean
  handleShareToClipboard: () => void
}

export const LessonModal = ({
  isOpen,
  handleClose,
  setIsStatsModalOpen,
  guesses,
  isGameLost,
  isHardMode,
  isDarkMode,
  isHighContrastMode,
  handleShareToClipboard,
}: Props) => {
  return (
    <BaseModal title={solution} isOpen={isOpen} handleClose={handleClose}>
      <div className="absolute left-4 top-4">
        <ChartBarIcon
          className="h-6 w-6 mr-3 cursor-pointer dark:stroke-white"
          onClick={() => setIsStatsModalOpen(true)}
        />
      </div>
      <div className="flex flex-col mt-2 text-gray-900 dark:text-gray-100">
        {lesson}
      </div>
      <div className="flex justify-evenly">
        <div className="mt-6 feedbackButton">
          <a
            className="bg-gray-800 dark:bg-gray-300 w-6/12 mt-3 px-8 py-3 self-center cursor-pointer rounded-2xl"
            href={link}
          >
            <button
              type="button"
              className="text-white dark:text-gray-800 feedbackButton"
              tabIndex={-1}
            >
              Learn More
            </button>
          </a>
        </div>
        <button
          type="button"
          className="bg-indigo-600 text-white hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:text-sm w-6/12 mt-3 px-8 py-3 self-center cursor-pointer rounded-2xl feedbackButton"
          onClick={() => {
            shareStatus(
              solution,
              guesses,
              isGameLost,
              isHardMode,
              isDarkMode,
              isHighContrastMode,
              handleShareToClipboard
            )
          }}
        >
          {SHARE_TEXT}
        </button>
      </div>
    </BaseModal>
  )
}
